import React from "react"
import { graphql, Link } from "gatsby"

export default function GiftCertificateSection() {
  const giftCertificateBackground = "/gift-certificate-pattern.jpg";
  return (
     <section className="section banner no-overlay has-text-centered" style={{
                    backgroundImage: `url(${giftCertificateBackground})`,
                    height: "auto"
                  }}>
      <div className="container">
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", position:"relative", zIndex:"3"}}>
          <div>
            <div className="content markdown has-text-secondary">
              <h3>Gift certificates available!</h3>
              <p>To place an order online, simply send Anne an email outlining which service(s) you'd like added to the gift certificate. All orders must be made via email.</p>
            </div>
            <div className="content">
              <Link className="button is-secondary transition wide" to="/contact">CONTACT ANNE</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}