import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'

import archwayIcon from "../assets/archway-icon.svg"

import image1 from "../assets/home/anne-squat-02.jpg"
import image2 from "../assets/home/sitting-on-dock.jpg"

import GiftCertificateSection from "../components/gift-certificate-section"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark
  const seytBanner = "/home/seyt-home-banner.jpg";

  const [playingVideo, setPlayingVideo] = React.useState(false);

  return (
    <Layout title="Anne Pitman" description="An virtual space where you can learn all about Anne Pitman, the classes, workshops, practices, and events she offers, as well as her involvement in The School of Embodied Yoga Therapy.">
      { playingVideo == true &&
        <div className="modal is-active video-modal">
          <div className="modal-background"></div>
          <div className="modal-content" style={{width:"100%", height:"100%", maxHeight: "100%", overflow: "hidden"}}>
            <iframe src='https://www.youtube.com/embed/Frkc7lPW_4Y?autoplay=1&loop=1&autohide=1'
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
            style={{width:"100%", height:"100%"}}
            />
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setPlayingVideo(false)}></button>
        </div>      
      }
      <section className="hero home-banner is-fullheight-with-navbar" style={{position: "relative", 
            backgroundImage: `url("/home/anne-video-thumbnail.jpg")`, backgroundPosition: "center",
            backgroundSize: "cover"}}>
        <div className="hero-body" style={{position:"relative", zIndex:"3"}}>
          <div className="container content">
            <h1 className="title is-1 has-text-white">{frontmatter.title}</h1>
            <p className="is-size-4-tablet is-size-6-mobile has-text-weight-semibold" style={{maxWidth: "700px"}}>
              {frontmatter.intro}
            </p>
            <a className="button big white transition" onClick={() => setPlayingVideo(true)}>PLAY VIDEO</a>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container"> 
          <div className="columns is-vcentered">            
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.booktext)}} />
              <Link className="button is-secondary half transition" to="/book">LEARN MORE</Link>
            </div>
            <div className="column">
              <img src="img/book-cover.jpg" style={{maxHeight:"450px", margin: "auto"}} alt="Book cover" className="pink-border" />
            </div>
          </div>
        </div>
      </section>
      <section className="section no-top-padding">
        <div className="container" style={{position: "relative"}}> 
          <div className="archway-container">
            <img src={archwayIcon} alt="Archway icon" style={{width: "100%", height: "100%", opacity:"0.2"}} />
          </div>     
          <h2 className="has-text-secondary has-text-centered title is-2" style={{margin: "auto", position:"relative", zIndex:"3"}}>
            {frontmatter.quote}       
          </h2>
        </div>
      </section>
      <section className="section no-top-padding">
        <div className="container"> 
          <div className="columns is-vcentered">
            <div className="column">
              <img src={image1} alt="Anne squatting" className="pink-border" />
            </div>
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text1)}} />
              <Link className="button is-secondary half transition" to="/classes-and-events">SEE SCHEDULE</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section banner mobile-background-position-65 has-text-centered" style={{
                    backgroundImage: `url(${seytBanner})`,
                    height: "auto"
                  }}>
        <div className="container">
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", position:"relative", zIndex:"3"}}>
            <div>
              <div className="content markdown has-text-white" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text2)}} />
              <div className="content">
                <Link className="button white transition wide" to="/school">LEARN MORE</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container"> 
          <div className="columns is-vcentered">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text3)}} />  
              <Link className="button is-secondary half transition" to="/practices">LEARN MORE</Link>         
            </div>
            <div className="column">
              <img src={image2} alt="woman sitting on a dock" className="pink-border" />
            </div>
          </div>
        </div>
      </section>
      <GiftCertificateSection />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        intro
        booktext
        quote
        text1
        text2
        text3
        quote
      }
    }
  }
`